import { useUserId } from '@/services/User/hooks'
import { routes } from '@shared/constants/routes'
import { useEffect, useState } from 'react'

/**
 * Returns the appropriate home URL depending on if the user is logged in or not.
 */
export const useHomeUrl = () => {
  const userId = useUserId()
  const [url, setUrl] = useState<string>(routes.UNGATED_HOMEPAGE.url)

  useEffect(() => {
    if (userId) {
      setUrl(routes.RETAILER_HOMEPAGE.url)
    }
  }, [userId])

  return url
}
